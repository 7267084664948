<template>
  <b-card
    class="card-app-design rooms-container"
  >
    <b-row>
      <b-col>
        <p>Track Livestock</p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
}
</script>
